export const instagram = "https://www.instagram.com/maltiti_a_enterprise/";

export const facebook = "https://web.facebook.com/mhaltiti99";

export const linkedin =
  "https://www.linkedin.com/company/maltiti-a-enterprise/";

export const email = "info@maltitiaenterprise.com";

export const backendUrl = "https://127.0.0.1:8000";
